import { prepareApartmentBody } from "../../utils/api_params";
export const apartmentApi = (api) =>
  api.injectEndpoints({
    endpoints: (build) => ({
      getApartments: build.query({
        query: () => {
          return `v1/apartments`;
        },
      }),
      getApartmentDevices: build.query({
        query: (id) => {
          return `v1/apartments/${id}/devices`;
        },
      }),
      addApartment: build.mutation({
        query: (attributes) => ({
          method: "POST",
          url: "v1/apartments",
          body: JSON.stringify(prepareApartmentBody(attributes)),
        }),
      }),
    }),
  });
