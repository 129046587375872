import { prepareUserBody } from "../../utils/api_params";
export const authApi = (api) =>
  api.injectEndpoints({
    endpoints: (build) => ({
      signout: build.mutation({
        query: () => ({
          url: "v1/logout",
          method: "DELETE",
        }),
      }),
      addUser: build.mutation({
        query: (attributes) => ({
          url: "/v1/users",
          method: "POST",
          body: JSON.stringify(prepareUserBody(attributes)),
        }),
      }),
    }),
  });
