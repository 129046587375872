import React, { useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Tooltip,
  Box,
  Typography,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate, useLocation } from "react-router-dom";
import theme from "./theme";
import { store } from "../redux/store";

const headerStyles = {
  root: {
    flexGrow: 1,
    marginRight: 7,
    marginLeft: 7,
    marginBottom: 2,
  },
  toolbar: {
    justifyContent: "space-between",
  },
  rightIconsContainer: {
    display: "flex",
    alignItems: "center",
  },
};

function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const [logout, { isSuccess }] = store.useSignoutMutation();

  useEffect(() => {
    if (isSuccess) {
      navigate(`/registration`);
    }
  }, [isSuccess]);
  const onHomeClick = () => {
    if (location.pathname !== "/" && location.pathname !== "/registration") {
      navigate("/");
    }
  };

  return (
    <Box style={headerStyles.root}>
      <AppBar position="static" sx={{ background: theme.palette.primary.main }}>
        <Toolbar sx={headerStyles.toolbar}>
          {/* Логотип */}
          {window?.LOGO?.logo ? (
            <img
              onClick={onHomeClick}
              style={{
                height: window?.LOGO?.height,
                width: window?.LOGO?.width,
                marginRight: 20,
                marginTop: 5,
                fill: window?.LOGO?.color,
                cursor: "pointer",
              }}
              src={window?.LOGO?.logo}
            />
          ) : (
            "LOGO"
          )}

          {/* Кнопки навигации и выхода (logout) в правом углу */}
          <Box sx={headerStyles.rightIconsContainer}>
            {/*             <IconButton
              disabled={
                location.pathname === "/" ||
                location.pathname === "/registration"
              }
            >
              <HomeIcon
                sx={{
                  fill:
                    location.pathname !== "/" &&
                    location.pathname !== "/registration" &&
                    "#ffff",
                }}
              />
            </IconButton> */}

            <IconButton
              disabled={
                location.pathname === "/" ||
                location.pathname === "/registration"
              }
              onClick={() => navigate(-1)}
            >
              <KeyboardBackspaceIcon
                sx={{
                  fill:
                    location.pathname !== "/" &&
                    location.pathname !== "/registration" &&
                    "#ffff",
                }}
              />
            </IconButton>

            {/* Кнопка выхода (logout) */}
            <Tooltip title="Logout">
              <IconButton
                onClick={() => logout()}
                disabled={location.pathname === "/registration"}
              >
                <LogoutIcon
                  sx={{
                    fill: location.pathname !== "/registration" && "#ffff",
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Header;
