import ApartmentCard from "./ApartamentCard";
import { useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useDispatch, useSelector } from "react-redux";
import { store } from "../redux/store";
import { actions } from "../redux/slices/meterSlice";
import { setApartmentName } from "../redux/slices/apartmentSlice";
import { useMediaQuery } from "@mui/material";
import {
  Tabs,
  Tab,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
} from "@mui/material";

const ApartamentIndex = () => {
  const dispatch = useDispatch();
  const [getAppartments] = store.useLazyGetApartmentsQuery();
  const apartments = useSelector((state) => state?.apartmentSlice?.apartments);
  useEffect(() => {
    getAppartments();
    dispatch(setApartmentName(null));
  }, []);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <>
      <Box
        display={"flex"}
        flexWrap={"wrap"}
        justifyContent={isMobile ? "center" : "start"}
      >
        {apartments?.map((item) => (
          <ApartmentCard
            id={item?.fields?.identifier}
            block={item?.condominium_name}
            subblock={item?.sub_condominium_name}
            apartment={item?.fields?.name}
          />
        ))}
      </Box>
    </>
  );
};

export default ApartamentIndex;
