import React, { useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Box } from "@mui/material";
import Header from "./Header";
import { useTranslation } from "react-i18next";
import Divider from "@mui/material/Divider";
import { store } from "../redux/store";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { useParams } from "react-router-dom";
import ApartamentIndex from "./ApartamentIndex";
import { setDevices } from "../redux/slices/apartmentSlice";
import { actions } from "../redux/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import theme from "./theme";

const MainPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { uuid } = useParams();
  useEffect(() => {
    dispatch(setDevices(null));
  });
  useEffect(() => {
    if (uuid) {
      dispatch(actions.setCode(uuid));
    }
  }, [uuid]);
  return (
    <>
      <Typography
        variant="h3"
        textAlign={"center"}
        sx={{
          color: theme.palette.primary.main,
          p: 2,
        }}
      >
        Select your apartment
        <IconButton onClick={() => navigate("/apartments/add")}>
          <Tooltip title={t("assoc_app")}>
            <AddIcon />
          </Tooltip>
        </IconButton>
      </Typography>

      <Divider />
      <ApartamentIndex />
    </>
  );
};
export default MainPage;
