import { Box, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import theme from "./theme";
import TextField from "@mui/material/TextField";
import { store } from "../redux/store";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMessage } from "../redux/slices/snackbarSlice";
const AddApartment = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [assocApp, { isSuccess }] = store.useAddApartmentMutation();
  const [code, setCode] = useState(null);
  const onSubmitClick = () => {
    assocApp({
      code: code,
    });
  };
  useEffect(() => {
    if (isSuccess) {
      dispatch(setMessage(t("associated")));
    }
  }, [isSuccess]);
  return (
    <Box>
      <Typography color={theme.palette.primary.main} variant="h3">
        {t("assoc_app")}
      </Typography>
      <TextField
        fullWidth
        sx={{ mt: 2 }}
        label={t("app_code")}
        onChange={(e) => setCode(e.target.value)}
      />
      <Button
        disabled={!code}
        variant="contained"
        onClick={onSubmitClick}
        sx={{ float: "right", mt: 2 }}
      >
        {t("assoc")}
      </Button>
    </Box>
  );
};

export default AddApartment;
