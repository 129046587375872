import { apartmentApi } from "./apartmentApi";
import { meterDataApi } from "./meterDataApi";
import { authApi } from "./authApi";
const modules = [
  {
    api: meterDataApi,
    keys: [
      "useLazyGetReadingsQuery",
      "useLazyGetConsumptionsQuery",
      "useLazyGetAllarmsQuery",
    ],
    endpointKey: "meter",
  },
  {
    api: apartmentApi,
    keys: [
      "useLazyGetApartmentsQuery",
      "useLazyGetApartmentDevicesQuery",
      "useAddApartmentMutation",
    ],
    endpointKey: "apartment",
  },
  {
    api: authApi,
    keys: ["useSignoutMutation", "useAddUserMutation"],
    endpointKey: "auth",
  },
];

export default (api) => {
  const result = {};

  modules.forEach(({ api: apiModule, keys, endpointKey }) => {
    const { endpoints, ...functions } = apiModule(api);

    keys.forEach((key) => {
      if (functions[key]) {
        result[key] = functions[key];
      }
    });

    result[`${endpointKey}Endpoints`] = endpoints;
  });

  return result;
};
