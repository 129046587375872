import Table from "./Table";
import MetersTableTab from "./MetersTableTab";
import { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import { useParams } from "react-router-dom";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { useDispatch, useSelector } from "react-redux";
import ConsumptionGrafic from "./ConsumptionGrafic";
import ApartmentConsumptionTable from "./ApartmentConsumptionTable";
import { useMediaQuery } from "@mui/material";
import { store } from "../redux/store";
import { actions } from "../redux/slices/meterSlice";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MeterCard from "./MeterCard";
import { actions as authActions } from "../redux/slices/authSlice";
import {
  Tabs,
  Tab,
  Typography,
  TextField,
  Tooltip,
  Box,
  Button,
} from "@mui/material";
import theme from "./theme";

const ApartamentShow = () => {
  const dispatch = useDispatch();
  const [getDevices] = store.useLazyGetApartmentDevicesQuery();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const devices = useSelector((state) => state?.apartmentSlice?.devices);
  const apartment = useSelector(
    (state) => state?.apartmentSlice?.apartmentName
  );
  const { id } = useParams();
  const [meterType, setMeterType] = useState(["AFS"]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  useEffect(() => {
    if (id) {
      getDevices(id);
    }
  }, [id]);
  useEffect(() => {
    dispatch(actions.meterCleanUp());
    dispatch(authActions.setCode(null));
  }, []);
  return (
    <Box sx={{ ml: 2, height: 835 }}>
      <Typography
        sx={{ mt: 3 }}
        variant="h3"
        color={theme?.palette?.primary?.main}
      >
        {`Appartamento  ${apartment ?? ""}`}
      </Typography>
      <Box
        display={"flex"}
        sx={{ mt: 2 }}
        flexWrap={"wrap"}
        justifyContent={isMobile ? "center" : "start"}
      >
        {devices?.map((item) => (
          <MeterCard
            type={item?.fields?.type}
            date={item?.fields?.last_reading?.datetime_utc}
            value={item?.fields?.last_reading?.value}
            unit={item?.fields?.last_reading?.unit}
            coldValue={item?.fields?.cold_last_reading?.value}
            heatValue={item?.fields?.heat_last_reading?.value}
            colorUnit={item?.fields?.cold_last_reading?.unit}
            colorDate={item?.fields?.cold_last_reading?.datetime_utc}
            serial={item?.fields?.serial ?? item?.serial_number}
            appId={id}
            meterId={item?.id}
            meter={item}
            maxWidth="21rem"
          />
        ))}
      </Box>
    </Box>
  );
};

export default ApartamentShow;
